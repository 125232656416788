import { } from './modules/utilities/observer.js'
import { navMenu } from './modules/nav-menu.js'
import { hero } from './modules/hero.js'
import { profile } from './modules/profile.js'
import { skills } from './modules/skills.js'
import { technologies } from './modules/technologies.js'
import { database } from './modules/database.js'
import { getInTouch } from './modules/get-in-touch.js'

navMenu.init();
hero.init();
profile.init();
skills.init();
technologies.init();
database.init();
getInTouch.init();
