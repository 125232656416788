import { scrambleText } from './utilities/scramble-text.js'

export const skills = (function () {
    let activeCard;

    const init = () => {
        document.addEventListener('element-observed', function (e) {
            if (e.detail.id === 'skills') {
                setScramble();
            }
        });

        document.querySelectorAll('#skills .skill-card-container').forEach(el => {
            const card = el.querySelector('.skill-card');
            card.addEventListener('click', function () {
                if (!card.classList.contains('dummy-card')) {
                    const clone = card.cloneNode(true);
                    setOverlay(clone);
                    card.classList.add('dummy-card');
                    activeCard = card;
                }                
            })


            const button = card.querySelector('button');
            button.addEventListener('click', function (e) {
                e.stopPropagation();
                card.classList.remove('dummy-card');
                const overlay = document.querySelector('.overlay');
                overlay.remove();
                activeCard = null;
            })
        });
    }

    const setScramble = () => {
        const title = document.querySelector('#skills h2');
        scrambleText(title, 'Skills', 1500);
    }

    const setOverlay = (cardClone) => {
        cardClone.classList.add('active-card');
        const overlay = document.createElement('div');
        overlay.classList.add('overlay');
        document.body.appendChild(overlay);
        overlay.appendChild(cardClone);
        overlay.addEventListener('click', function () {
            document.querySelectorAll('.skill-card').forEach(card => {
                card.classList.remove('dummy-card');
            })
            overlay.remove();
            activeCard = null;
        })
    }


    return {
        init: init
    }
})();

