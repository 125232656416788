import { scrambleText } from './utilities/scramble-text.js'

export const database = (function () {

    const init = () => {
        document.addEventListener('element-observed', async function (e) {
            if (e.detail.id === 'database') {
                const title = document.querySelector('#databaseHeader');
                scrambleText(title, 'Databases', 1500);
                const typedTextElement = document.getElementById("typed-text");
                await typeText(typedTextElement, "SELECT * FROM Databases", 4000);              
            }
        });
    }

    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const typeText =  (element, text, duration) => {
        const textArray = text.split(''); // Convert string to array
        const totalChars = textArray.length;
        const interval = duration / totalChars; // Time between each character

        let typedText = '';
        let charIndex = 0;

        const typingInterval = setInterval(async () => {
            typedText += textArray[charIndex];
            element.innerText = typedText; // Update the element with the typed text

            charIndex++;

            if (charIndex === totalChars) {
                clearInterval(typingInterval); // Stop the interval once all text is typed
                await delay(1000);
                document.getElementById('typewriter').outerHTML = `<div class="mx-auto roller-container">
                                                                        <div class="roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                                   </div>`
                await delay(2000);
                const response = await fetch('/components/databases',{
                    headers: {
                        'X-Component' : 'true'
                    }
                });

                if (response.ok) {
                    const html = await response.text();
                    const roller = document.querySelector('.roller-container');
                    roller.outerHTML = html;
                    const section = document.getElementById('database');
                    const img = document.createElement('img');
                    img.src = '/assets/images/Ellipse-min.png';
                    img.alt = 'ellipse';
                    img.classList.add('absolute', 'database-bg-img');
                    section.append(img);
                }
            }
        }, interval);
    }

    return {
        init: init
    }

})();