export function scrambleText(element, targetText, duration = 2000, scrambleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") {
    const interval = 50;  // Time between updates in milliseconds
    const steps = duration / interval;
    let currentStep = 0;

    const originalText = element.innerText;
    const targetLength = targetText.length;
    let scrambledText = "";

    const updateScramble = () => {
        scrambledText = "";
        for (let i = 0; i < targetLength; i++) {
            if (i < Math.floor(currentStep / steps * targetLength)) {
                scrambledText += targetText[i]; // Reveal correct character
            } else {
                scrambledText += scrambleChars[Math.floor(Math.random() * scrambleChars.length)];
            }
        }

        element.innerText = scrambledText;
        currentStep++;

        if (currentStep <= steps) {
            setTimeout(updateScramble, interval);
        } else {
            element.innerText = targetText;  // Ensure final text is exactly the target text
        }
    };

    updateScramble();
}

//// Usage Example:
//const element = document.getElementById("scrambled-text");
//scrambleText(element, "Hello World", 2000);
