const observer = new IntersectionObserver(handleIntersection,{
    root: null,           // Use the viewport as the root
    rootMargin: '0px',     // No margin around the root (can be adjusted for lazy loading, etc.)
    threshold: 0.1         // Trigger when 10% of the element is visible
});

function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');

            document.dispatchEvent(new CustomEvent('element-observed', {
                detail: {
                    id: entry.target.id
                }
            }))

            observer.unobserve(entry.target);
        }
    });
}

document.querySelectorAll('.observe').forEach(i => observer.observe(i));


document.querySelectorAll('.input-control').forEach(x => {
    x.addEventListener('input', function (e) {
        const value = e.target.value;

        if (value) {
            e.target.classList.add('value-input');
        } else {
            e.target.classList.remove('value-input');
        }
    })
});

function isInViewport(element) {
    const rect = element.target.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}