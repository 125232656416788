import { scrambleText } from './utilities/scramble-text.js'

export const profile = (function () {

    const init = () => {
        document.addEventListener('element-observed', function (e) {
            if (e.detail.id === 'profile') {
                const title = document.querySelector('#profile h2');
                scrambleText(title, 'Profile', 1500);
            }
        });
    }

    return {
        init: init
    }

})();