import { scrambleText } from './utilities/scramble-text.js'

export const technologies = (function () {

    const init = () => {
        document.addEventListener('element-observed', async function (e) {
            if (e.detail.id === 'technologies') {
                const titleSmall = document.querySelector('.tech-header-small');
                const title = document.querySelector('#technologies h2');
                scrambleText(titleSmall, 'Technologies', 1750);
                await delay(1000);
                scrambleText(title, 'Technologies', 1750);
            }
        });
    }

    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return {
        init: init
    }

})();