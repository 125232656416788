export function validateForm(form) {
    const inputs = form.querySelectorAll('input, textarea');
    let valid = true;

    inputs.forEach(el => {
        const isEmail = el.type == 'email';

        clearValidation(el);
        if (!isRequired(el)) {
            valid = false;
            return;
        }

        if (isEmail && !validateEmail(el)) {
            valid = false;
            return;
        }
    });

    return valid;
}

export function disableInputs(form) {
    const inputs = form.querySelectorAll('input, textarea, button');

    inputs.forEach(el => {
        el.disabled = true;
    });
}

export function enableInputs(form) {
    const inputs = form.querySelectorAll('input, textarea, button');

    inputs.forEach(el => {
        el.disabled = false;
    });
}

function isRequired(el) {
    const required = el.dataset.valRequired;

    if (required && !el.value) {

        setValidationMessage(el, required);

        return false;
    }

    return true;
}

function validateEmail(el) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const email = el.value;

    if (email) {
        const valid = emailRegex.test(email);

        if (!valid) {
            setValidationMessage(el, 'A valid email is required');
        }

        return valid;
    }

    return false;
    
}

function setValidationMessage(el, message) {
    const id = el.id;
    const valMessage = document.querySelector(`.input-val-message[for="${id}"]`);

    if (valMessage) {
        valMessage.innerHTML = message;
    }

    el.classList.add('invalid');
}

function clearValidation(el) {
    el.classList.remove('invalid');
    const id = el.id;
    const valMessage = document.querySelector(`.input-val-message[for="${id}"]`);

    if (valMessage) {
        valMessage.innerHTML = null;
    }

    
}