
export const navMenu = (function () {

    let links;

    const init = () => {
        links = document.querySelectorAll('nav a');

        links.forEach(link => {
            link.addEventListener('click', function () {
                const ddButton = document.querySelector('.dropdown-btn');
                ddButton.blur();
                const dropDown = document.querySelector('.dropdown-content');
                dropDown.blur();
                document.body.focus();
            })
        });

        document.addEventListener('element-observed', async function (e) {
            const id = e.detail.id;

            setNav(id);
        });

        const setNav = (id) => {
            links.forEach(link => {
                link.classList.remove('in-show');
                const urlObj = new URL(link.href);

                if (urlObj.hash = `#${id}`) {
                    link.classList.add('in-show');
                }
            });

            
        }
    }

    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return {
        init: init
    }

})();