import { scrambleText } from './utilities/scramble-text.js'
import { validateForm, disableInputs, enableInputs } from './utilities/forms.js'

export const getInTouch = (function () {
    const init = () => {
        document.addEventListener('git-form-submitted', async function (e) {
            e.preventDefault();
            const button = document.querySelector('.g-recaptcha');

            try {
                const data = new FormData(e.detail.form);
                disableInputs(e.detail.form);
                removeErrorMessage();
                if (!validateForm(e.detail.form)) {
                    return;
                }

                button.classList.add('sending');
                button.disabled = true;               

                const response = await fetch('/api/getInTouch', {
                    method: 'POST',
                    body: data
                })

                if (response.ok) {
                    showOkMessage();
                } else {
                    showErrorMessage();
                }
            } catch (e) {
                showErrorMessage();
            } finally {
                enableInputs(e.detail.form);
                button.classList.remove('sending');
                button.disabled = false;
            }
            
        });

        document.addEventListener('element-observed', function (e) {
            if (e.detail.id === 'getInTouch') {
                setScramble();
            }
        });
    }

    const showOkMessage = () => {
        const html = ` <div class="flex flex-col justify-center items-center h-full gap-10">
                        <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="90" cy="90" r="86" stroke="#58EB55" stroke-width="8" />
                            <path d="M40 102.5L64.5151 132.672C65.2931 133.63 66.7441 133.662 67.5631 132.739L141 50" stroke="#58EB55" stroke-width="8" stroke-linecap="round" />
                        </svg>
                            <p>Thank you for submitting an enquiry. I’ll get back to you as soon as I can.</p>
                    </div>`

        const el = document.getElementById('getInTouchForm');

        if (el) {
            el.innerHTML = html;
        }
    }

    const showErrorMessage = () => {
        const errMessage = document.createElement('p');
        errMessage.classList.add('text-error', 'form-error');
        const form = document.getElementById('getInTouchForm');
        form.appendChild(errMessage);
    }

    const removeErrorMessage = () => {
        const errMessage = document.querySelector('.form-error');

        if (errMessage) {
            errMessage.remove();
        }

       
    }

    const setScramble = () => {
        const title = document.querySelector('#getInTouchHeader');
        scrambleText(title, 'Get in touch', 2000);
    }


    return {
        init: init
    }
})();