import { scrambleText } from './utilities/scramble-text.js'

export const hero = (function () {
    const init = () => {
        document.addEventListener('element-observed', function (e) {
            if (e.detail.id === 'hero') {
                setScramble();
            }
        });
    }

    const setScramble = () => {
        const jobTitle = document.getElementById('jobTitle');
        scrambleText(jobTitle, 'Full Stack Developer', 2000);
    }


    return {
        init: init
    }
})();